import React from "react";
import FooterLogo from "../../../asset/footer-logo.svg";
import TwitterLogo from "../../../asset/social-media/Twitter.svg";
import FbLogo from "../../../asset/social-media/Facebook.svg";
import LinkedInLogo from "../../../asset/social-media/Linkedin.svg";
import RightArrow from "../../../asset/footer/Right-Arrow.svg";
import IdCard from "../../../asset/footer/jobs_id_card.svg";
import Clock from "../../../asset/footer/clock.svg";
import Analytics from "../../../asset/footer/analytics.svg";
import Handshake from "../../../asset/footer/people_handshake.svg";
import GirdDashboard from "../../../asset/footer/dashboard-girl.svg";
import Vacancies from "../../../asset/footer/girl-shout-job.svg";
import Hiring from "../../../asset/footer/guy_giving_presentation.svg";
import Onboarding from "../../../asset/footer/doctor-discussion.svg";
import SoltuionCard from "../../../components/Solution_Card";
import JobListingCard from "../../../components/JobListingCard";
import HowJobizoWorksCard from "../../../components/HowJobizoWorkCard";

import "./Landing.scss";

const Landing = () => {
  const handleOnChange = {};
  return (
    <>
      <div className="landing">
        <section className="solutions">
          <p className="heading">Healthcare Workforce Excellence Solution</p>
          <div className="light_blue_bg_div">
            <div className="card_section">
              <SoltuionCard
                title="10,000+"
                redText="Jobs Fulfilled"
                alt=""
                icon={IdCard}
                desc="We have successfully matched thousands of healthcare professionals with the right jobs."
                // knowMore="#"
              />
              <SoltuionCard
                title="65,000+"
                redText="Database"
                icon={Analytics}
                alt="dashboard"
                desc="Access an extensive database of over 65,000 qualified healthcare professionals ready to be hired."
                // knowMore="#"
              />
              <SoltuionCard
                title="400+"
                redText="Employers"
                icon={Handshake}
                alt="handshake"
                desc="Trusted by more than 400 clients for their healthcare hiring needs, ensuring quality and reliability."
                // knowMore="#"
              />
              <SoltuionCard
                title="5,00,000"
                redText="Care Hours"
                icon={Clock}
                alt="clock"
                desc=" Providing dedicated and professional healthcare services, totaling over 500,000+ hours of patient care."
                // knowMore="#"
              />
            </div>
          </div>
        </section> 
        <section className="jobs_listing">
          <p className="heading">Latest Exclusive Jobs on Jobizo</p>
          <div className="job_listing_div">
          <JobListingCard/>
          {/* <JobListingCard/>
          <JobListingCard/>
          <JobListingCard/> */}
          </div>
        </section>
        <section className="how_jobizo_works">
          <p className="how_jobizo_works_heading">How Jobizo Works for Employers ?</p>
          <p className="how_jobizo_works_desc">Jobizo streamlines the hiring process for employers by connecting them with qualified healthcare professionals quickly and efficiently. Employers can post job openings, review candidate profiles, and schedule interviews all within the platform, ensuring a seamless and hassle-free recruitment experience!</p>
        <div className="how_jobizo_works_light_blue_bg_div">
        <h1
        // className="how_jobizo_works_heading"
        style={{
      //  paddingBottom: "-20px",
      fontFamily: "DM Sans",
      // fontFamily: "Noto Sans",
      fontSize: "27px",
       marginTop: "-30px",
       textAlign: "center",
      //  color: "#000000",
      // color: "#555555",
      letterSpacing: "4.5%",
      lineHeight: "1.3",
      color: "#333333",
       fontWeight: "normal"
      }}
       >Our Key Features</h1>
          <div className="card_section">
          
          <HowJobizoWorksCard
          image={GirdDashboard}
          // heading="Dashboard Login"
          heading="Quick Job Posting"
          // desc="Log in to employer dashboard"
          alt="dashboard"
          />
          <HowJobizoWorksCard
          image={Vacancies}
          // heading="Vacancies"
          heading="Instant Match Making"
          // desc="Find Vacancies for Professionals"
          alt="shoutout"
          />
          <HowJobizoWorksCard
          image={Hiring}
          // heading="Hiring"
          heading="Review Based Hiring"
          // desc="Find Professionals as per your need"
          alt="hiring_process"
          />
          <HowJobizoWorksCard
          image={Onboarding}
          // heading="Onboarding"
          heading="NABH/JCI Ready"
          // desc="Onboard Healthcare Professionals"
          alt="doctor_getting_onboarded"
          />
          </div>
        </div>
        </section>
        <footer className="footer">
          <div className="footer_container">
            <div className="footer_contact_us">
              <div className="first-block">
                <p className="p1 font-700">Need more information?</p>
                <p className="font-400">
                  Write your concern to us and our specialist will get back to
                  you.
                </p>
              </div>
              <div className="second-block">
                {/* <button className="blue_button"> Connect Us</button> */}
                <a href="https://jobizo.com/about/#contact" className="blue_button">
      Contact Us
    </a>
              </div>
            </div>
            <div className="footer_wrapper">
              <div className="footer_links_section">
                {/* <div className="footer_link_block">
                  <p className="footer-link-heading font-500"> Product</p>
                  <div className="footer_links">
                    <a>Employee database</a>
                    <a>Payroll</a>
                    <a>Absences</a>
                    <a>Time tracking</a>
                    <a>Shift planner</a>
                    <a>Recruiting</a>
                  </div>
                </div> */}
                <div className="footer_link_block_2">
                  <p className="footer-link-heading font-500"> Information</p>
                  <div className="footer_links">
                    <a href="https://jobizo.com/faq/" style={{ color: 'white' }}> FAQ</a>
                    <a href="https://jobizo.com/blog/" style={{ color: 'white' }}> Blog</a>
                    {/* <a href="https://jobizo.com/about/#contact">Support</a> */}
                  </div>
                </div>
                <div className="footer_link_block">
                  <p className="footer-link-heading font-500"> Company</p>
                  <div className="footer_links">
                    <a href="https://jobizo.com/about/" style={{ color: 'white' }}>About us</a>
                    {/* <a>Careers</a> */}
                    {/* <a href="https://jobizo.com/about/#contact">Contact us</a> */}
                    {/* <a href="https://jobizo.com/about/#contact">Contact us</a> */}
                    <a href="https://jobizo.com/about/#Our-Legacy-Clients" style={{ color: 'white' }}>Our Clients</a>
                  </div>
                </div>
                {/* <div className="footer_link_block_1">
                  <p className="footer-link-heading font-500">Register Now</p>

                  <div className="input_form">
                    <input
                      type="email"
                      name="email"
                      className="subscribe_field"
                      //   value={email}
                      placeholder="Email address"
                      onChange={handleOnChange}
                    />

                    <button className="submit_button">
                      <div className="arrow_icon">
                        <img className="img" src={RightArrow} alt="arrow" />
                      </div>
                    </button>
                  </div>

                  <p className="p6 footer_desc">
                    Hello, we are Jobizo. Our goal is to translate the positive
                    effects from revolutionizing how companies engage with their
                    clients & their team.
                  </p>
                </div> */}
              </div>
              <div className="divider"></div>

              <div className="footer_last_section">
                <div className="footer_logo">
                  <img className="img" src={FooterLogo}></img>
                </div>

                <div className="terms_block">
                  <a href="https://jobizo.com/terms-and-conditions/" className="p4 font-400">
                    Terms
                  </a>
                  <a href="https://jobizo.com/platform-user-engagement/" className="p4 font-400">
                    Privacy
                  </a>
                  {/* <a href="#" className="p4 font-400">
                    Cookies
                  </a> */}
                </div>

                <div className="social_media_block">
                  <a href="https://www.linkedin.com/company/jobizoindia/mycompany/" className="social_link">
                    <img className="img" src={LinkedInLogo}></img>
                  </a>
                  <a href="https://www.facebook.com/jobizo.india/" className="social_link">
                    <img className="img" src={FbLogo}></img>
                  </a>
                  {/* <a href="#" className="social_link">
                    <img className="img" src={TwitterLogo}></img>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Landing;
